import React from "react";
import {FaApple, FaCcApplePay, FaChrome, FaCode, FaExternalLinkAlt, FaGooglePlay, FaUnity} from "react-icons/fa";
import Images from "./Images";
import Utils from "../utils/Utils";

class ProjectCard extends React.Component {

    constructor() {
        super();

        this.open = this.open.bind(this);
    }

    openLink(link) {
        window.location.href = link;
    }

    open(link, platform) {
        if (platform === 'unity') {
            this.props.onOpenGame(link)
            return;
        }
        this.openLink(link);
    }

    render() {
        const {icon, link, platforms, description, images, tags, platformLinks, title} = this.props.project;
        let tagId = 0;
        return (
            <div className="group w-full sm:w-1/2 m-4 mx-auto p-6 rounded-xl border-2 border-gray-300">
                <div className={"flex flex-wrap justify-start items-center gap-2"}>
                    {icon ? <div className="w-10">
                        <img
                            className="w-10 mx-auto shadow-xl"
                            src={icon}
                            alt="Profile face"
                        />
                    </div> : null}
                    <div className={"flex-grow"}/>
                    <h1 className="text-xl text-center font-bold">
                        {title}{" "}
                    </h1>
                    <div className={"flex-grow"}/>
                    {platforms.indexOf('android') !== -1 ? <span className={"cursor-pointer"}
                                                                 onClick={this.openLink.bind(this, platformLinks['android'])}><FaGooglePlay/></span> : null}
                    {platforms.indexOf('apple') !== -1 ?
                        <span className={"cursor-pointer"}
                              onClick={this.openLink.bind(this, platformLinks['apple'])}><FaApple/></span> : null}
                    {platforms.indexOf('unity') !== -1 ?
                        <span className={"cursor-pointer"}
                              onClick={this.props.onOpenGame.bind(this, platformLinks['unity'])}><FaUnity/></span> : null}
                    {platforms.indexOf('chrome') !== -1 ?
                        <span className={"cursor-pointer"}
                              onClick={this.openLink.bind(this, platformLinks['chrome'])}><FaChrome/></span> : null}
                </div>
                <hr className="my-4"/>

                <div
                    dangerouslySetInnerHTML={{__html: description}}
                />

                {images ? <Images images={images}/> : null}

                <div className="mt-4 mb-8 flex flex-wrap justify-center items-center gap-2">
                    {tags.map((tag) => (
                        <div key={`tag_${++tagId}`} className="px-4 py-1 border-2 rounded-full">{tag}</div>
                    ))}
                </div>

                {(platforms.indexOf('chrome') !== -1 || platforms.indexOf('unity') !== -1) && !Utils.isMobile() ?
                    <div className={"flex items-center justify-center"}>
                        <div
                            onClick={this.open.bind(this, platformLinks['chrome'] || platformLinks['unity'], platforms.indexOf('chrome') !== -1 ? 'chrome' : 'unity')}
                            className={"button"}>Otevřít
                        </div>
                    </div> : null}
            </div>
        );
    }
}


export default ProjectCard;
