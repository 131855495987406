import React from 'react'

function Footer({github}) {
    return (
        <div className="text-center w-full mt-16">
        </div>
    )
}

export default Footer
