import React from "react";

class GameOverlay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.hide = this.hide.bind(this);
    }

    show(game) {
        this.setState({
            ...this.state,
            visible: true,
            game: game
        });
    }

    hide() {
        this.setState({
            ...this.state,
            visible: false
        });
    }

    render() {
        if (!this.state.visible) {
            return <></>
        }
        return (<div onClick={this.hide}
                     style={{
                         zIndex: '10',
                         backgroundColor: 'rgba(51,51,51,0.44)',
                         position: 'fixed',
                         left: 0,
                         top: 0,
                         minWidth: '100%',
                         minHeight: '100%',
                     }}>
            <iframe
                allow={"autoplay"}
                src={`/games/${this.state.game}/index.html`}
                style={{border: "0px #000000 none", margin: 'auto'}}
                scrolling={"no"}
                height={"650px"}
                width={"960px"}/>
        </div>)
    }
}

export default GameOverlay;
