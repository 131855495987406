import React from "react";
import {FaRegArrowAltCircleRight} from "react-icons/fa";
import {FaRegArrowAltCircleLeft} from "react-icons/fa";

class Images extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentImageIndex: 0
        };

        this.nextImage = this.nextImage.bind(this);
        this.prevImage = this.prevImage.bind(this);
    }

    nextImage() {
        let nextIndex = this.state.currentImageIndex + 1;
        if (nextIndex === this.props.images.length) {
            nextIndex = 0;
        }

        this.setState({
            ...this.state,
            currentImageIndex: nextIndex
        });
    }

    prevImage() {
        let nextIndex = this.state.currentImageIndex - 1;
        if (nextIndex < 0) {
            nextIndex = this.props.images.length - 1;
        }

        this.setState({
            ...this.state,
            currentImageIndex: nextIndex
        });
    }


    render() {
        const images = this.props.images;
        const hasMoreImages = this.props.images.length > 1;
        const curImage = images[this.state.currentImageIndex];
        return (
            <div className="mt-4 mb-8 flex  justify-center items-center gap-2">
                <div className={"flex-grow"}/>
                {hasMoreImages ? <div onClick={this.prevImage} className={"cursor-pointer block"}>
                    <FaRegArrowAltCircleLeft/>
                </div> : null}
                {typeof curImage != 'string' ? <video width="320" height="240" controls poster={curImage.poster}>
                    <source src={curImage.video} type="video/mp4"/>
                    Video není podporováno
                </video> : <img
                    className="w-64 mx-auto shadow-xl rounded-xl"
                    src={curImage}
                    alt="Profile face"
                />}
                {hasMoreImages ? <div onClick={this.nextImage} className={"cursor-pointer"}>
                    <FaRegArrowAltCircleRight className={"cursor-pointer"}/>
                </div> : null}
                <div className={"flex-grow"}/>
            </div>
        );
    }
}


export default Images;
