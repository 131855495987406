import React from "react";
import {FaPalette, FaReact, FaCode, FaPhone} from "react-icons/fa";
import {CgMail} from "react-icons/all";

const Tags = {
    // Languages
    ReactJS: 'ReactJS',
    Swift: 'Swift',
    Kotlin: 'Kotlin',
    NodeJS: "NodeJS",
    CSharp: "C#",
    PHP: "PHP",
    Java: "Java",
    Typescript: "Typescript",

    // SQL Databases
    MySQL: "MySQL",
    Oracle: "Oracle",
    MSSQL: "MSSQL",

    // No SQL Databases
    ArangoDb: "ArangoDb",
    MongoDb: "MongoDb",

    // Techs
    Symetry: "Symetry",
    Datovky: "Datovky",
    Rossum: "Rossum",
    AzureRelay: "Azure Relay",
    LDAP: "LDAP",
    AzureAD: "AzureAD",
    Kerberos: "Kerberos",
    Rest: "Rest",
    Saml2: "Saml 2.0",
    Oauth: "OAuth 2.0",
    SocketIO: "Socket.io",
    Exchange: "Exchange",
    Docker: "Docker",


    // SVN
    Mercurial: "Mercurial",
    TeamCity: "TeamCity",
    Upsource: "Upsource",
    DMS: "DMS"


}

const BOOTSTRAP_FOR_SKILL_ICON = "text-4xl mx-auto inline-block";
const data = {
    name: "Michal Fuka",
    title: "Programátor, analytik, člověk.",
    social: {
        email: "xfukam00@gmail.com",
    },
    about: {
        title: "O mě",
        description:
            "Programování byl vždy můj koníček, začal jsem v roce 2011 v Delphi. Zajímalo mě reverzní inženýrství (Assembler) a vše co se software týká. " +
            "Později jsem měl potřebu vytvořil něco viditelného a začal programovat pro Android (tenkrát pouze Java + Eclypse = horor :D). Neúspěšně jsem  studoval VUT a přešel na pardubickou VŠ IT. " +
            "Po absolvování prvního ročníku (2014) jsem na studia zanevřel. Žádný rozvoj a praktické znalosti. První životopis, první práce. Chtěný nástup na Javu se změnil na PHP - nelituji. Ve firmě jsem 8 let. " +
            "Pokrok nezastavíš, z PHP se stal NodeJS a mé profilování backend vývojáře. Paralelně však vyvýjím projekty na IČ, mám za sebou prezentovatelné projekty pro iOS, Android i Web.",
    },
    skills: [
        {
            skillName: "+420 608 161 822",
            skillIcon: <FaPhone className={BOOTSTRAP_FOR_SKILL_ICON}/>,
        },
        {
            skillName: "xfukam00@gmail.com",
            skillIcon: <CgMail className={BOOTSTRAP_FOR_SKILL_ICON}/>,
        },
    ],
    projects: [
        {
            title: "Neit Consulting od 2014",
            platforms: [],
            platformLinks: {},
            description: "Software pro procesní řízení. Přepis produktu z PHP do NodeJS. Různorodé napojení a migrace.<br /><br />Oficiální pracovní náplň:<br />" +
                "🡆 Analyzování procesů, legislativních a technických podmínek a požadavků uživatelů.<br />" +
                "🡆 Navrhování a projednávání koncepce řešení počítačových aplikací.<br />" +
                "🡆 Vytváření programových a datových struktur.<br />" +
                "🡆 Testování a ladění komplexních programových celků a složitých úloh.<br />" +
                "🡆 Vytváření systémové a uživatelské dokumentace.<br />",
            tags: [
                Tags.NodeJS,
                Tags.CSharp,
                Tags.PHP,

                Tags.Oracle,
                Tags.MSSQL,
                Tags.ArangoDb,

                Tags.Symetry,
                Tags.Mercurial,
                Tags.AzureRelay,
                Tags.LDAP,
                Tags.AzureAD,
                Tags.Kerberos,
                Tags.Rest,
                Tags.Saml2,
                Tags.Oauth,
                Tags.TeamCity,
                Tags.Upsource,
                Tags.Docker,
                Tags.DMS,


            ],
            link: "https://neit.cz/"
        },
    ],
    icProjects: [
        {
            title: "Slovní fotbal",
            platforms: ['android', 'apple', 'chrome'],
            platformLinks: {
                apple: 'https://apps.apple.com/cz/app/slovni-fotbal/id1503671923',
                android: 'https://play.google.com/store/apps/details?id=fuka.michal.slovnfotbal2019',
                chrome: 'https://hernizona.online/slovnifotbal'
            },
            icon: '/promo/small/slovnifotbal.png',
            images: [
                '/promo/slovnifotbal/img1.png',
                '/promo/slovnifotbal/device-2019-03-25-183750.png',
                '/promo/slovnifotbal/device-2019-03-25-183816.png',
                '/promo/slovnifotbal/device-2019-03-25-183830.png',
                '/promo/slovnifotbal/device-2019-03-25-185425.png',
                '/promo/slovnifotbal/device-2019-03-25-185534.png',
                '/promo/slovnifotbal/device-2019-03-25-185625.png',
                '/promo/slovnifotbal/device-2019-03-25-185642.png',
                '/promo/slovnifotbal/device-2019-03-25-185722.png',
                '/promo/slovnifotbal/device-2019-03-25-185816.png',
                '/promo/slovnifotbal/device-2019-03-25-185843.png',
                '/promo/slovnifotbal/device-2019-03-25-185858.png',
                '/promo/slovnifotbal/device-2019-03-28-092222.png',
                '/promo/slovnifotbal/device-2019-03-29-151551.png',
                '/promo/slovnifotbal/device-2019-03-29-151601.png',
            ],
            description: "Slovní fotbal, projekt, který udržuji nejdéle. Má širokou základnu hráčů. " +
                "Hru Slovní Fotbal v originále vytvořil pan Endrle a začal éru firmy Geewa. Na škole jsem byl skalní hráč, ale chyběla mi varianta pro mobilní telefony. " +
                "Tehdy začal můj vývoj mobilních her s cílem 1000 stažení.",
            tags: [
                Tags.ReactJS,
                Tags.NodeJS,
                Tags.Kotlin,
                Tags.Swift,

                Tags.MySQL,

                Tags.Mercurial,
                Tags.SocketIO,
            ],
            link: "https://hernizona.online/slovnifotbal"
        },
        {
            title: "Kris Kross",
            platforms: ['android', 'chrome'],
            platformLinks: {
                android: 'https://play.google.com/store/apps/details?id=fuka.michal.kriskros',
                chrome: 'https://hernizona.online/kriskros'
            },
            description: "Kris Kross jsem vytvořil především po volání hráčů po alternativě ke končící verzi hry psané ve Flashi.",
            icon: '/promo/small/kriskross.png',
            images: [
                '/promo/kriskross/kris-kros-main.png',
                '/promo/kriskross/Screenshot_20210617_140051.png',
                '/promo/kriskross/Screenshot_20210617_140155.png',
                '/promo/kriskross/Screenshot_20210617_140222.png',
                '/promo/kriskross/Screenshot_20210617_140303.png',
            ],
            tags: [
                Tags.ReactJS,
                Tags.NodeJS,
                Tags.Kotlin,

                Tags.MySQL,
                Tags.MongoDb,

                Tags.Mercurial,
                Tags.SocketIO,
            ],
            link: "https://hernizona.online/kriskros"
        },
        {
            title: "Rezervační systém",
            platforms: [],
            platformLinks: {},
            description: "Kompletní dodávka rezervačního systému. Boj o zasedací místnost, ťukání, lezení a obtěžování. " +
                "Zasedací systém zobrazuje obsazenost místnosti, je univerzálně napsán jako součást projektu Intranet. Firemní zasedačka je napojena a synchronizována se serverem Exchange.",
            icon: '/promo/small/neit.jpg',
            images: [
                '/promo/zasedacka/20220211_145808.jpg',
                '/promo/zasedacka/20220216_183310.jpg',
                '/promo/zasedacka/20220211_145815.jpg',
                '/promo/zasedacka/20220211_145757.jpg',

                '/promo/zasedacka/20220211_145818.jpg',
                '/promo/zasedacka/20220211_145822.jpg',
                '/promo/zasedacka/20220216_183051.jpg',
                '/promo/zasedacka/20220216_183302.jpg',

            ],
            tags: [
                Tags.CSharp,
                Tags.Java,
                Tags.NodeJS,
                Tags.Typescript,

                Tags.MySQL,
                Tags.Exchange,
                Tags.Docker,
                Tags.DMS,

                Tags.Mercurial,
            ],
            link: "https://hernizona.online/kriskros"
        },
        {
            title: "Boxrun",
            platforms: ['unity'],
            platformLinks: {
                'unity': 'boxrun'
            },
            description: "Závodní hra, jedna z mých prvních Unity2D her.",
            icon: '/promo/small/boxrun.png',
            images: [
                {
                    video: '/promo/boxrun/trailer.mp4',
                    poster: '/promo/boxrun/box_run_overlay.png'
                }
            ],
            tags: [
                Tags.CSharp,
                Tags.Java,
                Tags.NodeJS,

                Tags.MySQL,

                Tags.Mercurial,
            ],
            link: "https://hernizona.online/kriskros"
        },
        {
            title: "Blocky Renegades",
            platforms: ['unity'],
            platformLinks: {
                'unity': 'blockyrenegades'
            },
            description: "Unity3D hra.",
            icon: '/promo/small/blockyrenegades.png',
            images: [
                '/promo/blockyrenegades/store_feature_graphics.png'
            ],
            tags: [
                Tags.CSharp,
                Tags.Java,
                Tags.NodeJS,

                Tags.MySQL,

                Tags.Mercurial,
            ],
            link: "https://hernizona.online/kriskros"
        },
        {
            title: "Dobyvatel",
            platforms: [],
            platformLinks: {},
            description: "Dobyvatel je jedna z počátečních her, dnes již nesplňuje produkční požadavky a byla stažena. Hru bylo v plánu přepsat a znovu vydat, ale vzhledem k ostatním projektům se tak pravděpodobně nestane.",
            icon: '/promo/small/dobyvatel.png',
            images: [
                '/promo/dobyvatel/image.png',
                '/promo/dobyvatel/2013-12-24 16.48.31.jpg',
                '/promo/dobyvatel/2013-12-24 16.54.04.jpg',
                '/promo/dobyvatel/2013-12-24 16.54.22.jpg',
                '/promo/dobyvatel/2013-12-24 16.54.33.jpg',
                '/promo/dobyvatel/2013-12-24 16.55.43.jpg',
                '/promo/dobyvatel/2013-12-24 16.55.48.jpg',
                '/promo/dobyvatel/2013-12-24 16.56.07.jpg',
                '/promo/dobyvatel/2013-12-24 16.56.43.jpg',
                '/promo/dobyvatel/2013-12-24 16.57.22.jpg',
                '/promo/dobyvatel/2013-12-24 16.57.42.jpg',
                '/promo/dobyvatel/2013-12-24 16.57.49.jpg',

            ],
            tags: [
                Tags.Java,

                Tags.MySQL,

                Tags.Mercurial,
            ],
            link: "https://hernizona.online/kriskros"
        },
        {
            title: "Rozcestník her",
            platforms: ['chrome'],
            platformLinks: {
                chrome: 'https://hernizona.online'
            },
            icon: '/promo/small/hernizona.svg',
            description: "Portál sdružující vlastní tvorbu. Záměrem bylo vytvořit hry, které upadli v zapomnění po konci Flash playeru.",
            tags: [
                Tags.ReactJS,
                Tags.NodeJS,

                Tags.MySQL,

                Tags.Mercurial,
            ],
            link: "https://hernizona.online"
        }
    ]
};
export default data;
