import React, {useEffect} from "react";
import "./App.css";
import About from "./partials/About";
import Card from "./partials/Card";
import Footer from "./partials/Footer";
import Projects from "./partials/Projects";
import Skills from "./partials/Skills";
import data from "./assets/data";
import AOS from "aos";
import "aos/dist/aos.css";
import GameOverlay from "./partials/GameOverlay";
import OkDialog from "./partials/OkDialog";
import Utils from "./utils/Utils";

class App extends React.Component {

    constructor(props) {
        super(props);

        AOS.init({
            once: true,
        });

        this._gameOverlay = React.createRef();
        this._okDialog = React.createRef();

        this.openGame = this.openGame.bind(this);
    }

    openGame(game) {
        if (Utils.isMobile()) {
            this._okDialog.current.show('Unity projekt lze zobrazit jen na PC. Pokud chcete vidět více, prosím projděte si fotky nebo otevřete projekt na počítači.');
            return;
        }
        this._gameOverlay.current.show(game);
    }

    render() {
        return (<><GameOverlay ref={this._gameOverlay} key={"GameOverlay"}/>
                <OkDialog ref={this._okDialog} key={"OkDialog"}/>
                <div className="min-h-screen py-10 px-3 sm:px-5 bg-gray-100">
                    <div data-aos="fade-down" data-aos-duration="800">
                        <Card key={"Card"} name={data.name} title={data.title} social={data.social}/>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
                        <About key={"About"} title={data.about.title} description={data.about.description}/>
                        <Skills key={"Skills"} skills={data.skills}/>
                        <Projects onOpenGame={this.openGame} key="ProjectsWork" title={"Zaměstnání"}
                                  projects={data.projects}/>
                        <Projects onOpenGame={this.openGame} key={"ProjectsIC"} title={"Vlastní projekty"} subTitle={"Kliknutím na ikonu platformy (vpravo nahoře) se projekt zobrazí/spustí."}
                                  projects={data.icProjects}/>
                    </div>
                </div>
            </>
        )
    }
}


export default App;
