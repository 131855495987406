import React from "react";
import {FaFacebook, FaFacebookMessenger, FaGithub, FaLinkedin, FaPhone} from "react-icons/fa";
import {FaDribbble} from "react-icons/fa";
import {FaTwitter} from "react-icons/fa";
import {FaRegEnvelope} from "react-icons/fa";
import {AiOutlineFacebook, CgFacebook, CgMail, FiFacebook, ImFacebook, RiFacebookFill} from "react-icons/all";

function Card({name, title, social: {github, dribbble, twitter, email}}) {
    return (
        <div className="w-full">
            <div className="flex flex-col justify-center max-w-xs mx-auto bg-white shadow-xl rounded-xl p-5">
                <div className="">
                    <img
                        className="w-32 mx-auto shadow-xl rounded-full"
                        src={'/profile.jpg'}
                        alt="Profile face"
                    />
                </div>
                <div className="text-center mt-5">
                    <p className="text-xl sm:text-2xl font-semibold text-gray-900">
                        {name}
                    </p>
                    <p className="text-xs sm:text-base text-gray-600 pt-2 pb-4 px-5 w-auto inline-block border-b-2">
                        {title}
                    </p>
                    <div className="flex align-center justify-center mt-4">

                        <a
                            className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-blue-600 hover:bg-blue-600 rounded-full hover:text-white transition-colors duration-300"
                            href={'https://www.facebook.com/michal.fuka'}
                        >
                            <FaFacebook/>
                            <span className="sr-only">Dribble</span>
                        </a>
                        <a
                            className="text-xl m-1 p-1 sm:m-2 sm:p-2 text-blue-600 hover:bg-blue-600 rounded-full hover:text-white transition-colors duration-300"
                            href={'https://www.linkedin.com/in/michal-fuka-4598a9127/'}
                        >
                            <FaLinkedin/>
                            <span className="sr-only">Dribble</span>
                        </a>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;
