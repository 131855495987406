import React from "react";

import './../assets/other.css'
import {FaFacebook, FaLinkedin} from "react-icons/fa";

class OkDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            text: '',
        };

        this.hide = this.hide.bind(this);
    }

    show(text) {
        this.setState({
            ...this.state,
            visible: true,
            text: text
        });
    }

    hide() {
        this.setState({
            ...this.state,
            visible: false
        });
    }

    render() {
        if (!this.state.visible) {
            return <></>
        }
        return (<div
            style={{
                zIndex: '10',
                backgroundColor: 'rgba(51,51,51,0.44)',
                position: 'fixed',
                left: 0,
                top: 0,
                minWidth: '100%',
                minHeight: '100%',
            }}>
            <div className="w-full" style={{top: '33%', position: 'absolute', transform: 'translate(0, -50%)'}}>
                <div className="flex flex-col justify-center max-w-xs mx-auto bg-white shadow-xl rounded-xl p-5"
                     style={{width: '80%'}}>
                    <div className="text-center mt-5">
                        <p className="text-xs sm:text-base text-gray-600 pt-2 pb-4 px-5 w-auto inline-block border-b-2"
                           style={{width: '80%'}}>
                            {this.state.text}
                        </p>
                        <div className="flex align-center justify-center mt-4">
                            <div onClick={this.hide} className={"button"}>OK</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default OkDialog;
