import React from "react";
import ProjectCard from "./ProjectCard";
import {FaCode, FaGooglePlay} from "react-icons/fa";
import SkillCard from "./SkillCard";
import {GrGooglePlay, SiGoogleplay} from "react-icons/all";
import * as PropTypes from "prop-types";


function FaGoogleplay(props) {
    return null;
}

FaGoogleplay.propTypes = {className: PropTypes.string};


class Projects extends React.Component {

    render() {
        let i = 0;
        return (
            <div>
                <h1 className="mt-8 text-2xl md:text-4xl text-center font-extrabold">{this.props.title}</h1>
                {this.props.subTitle ? <span
                    className={"flex justify-center content-center text-xs sm:text-base text-gray-600 inline-block"}>{this.props.subTitle}</span> : null}
                {this.props.projects.map((project) => (
                    <ProjectCard onOpenGame={this.props.onOpenGame} key={`ProjectCard_${++i}`} project={project}/>
                ))}
            </div>
        );
    }
}

export default Projects;
