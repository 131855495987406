import React from "react";
import SkillCard from "./SkillCard";

function Skills({skills}) {
    let skillId = 0;
    return (
        <div className="flex flex-col sm:flex-row align-center justify-center max-w-4xl mx-auto mt-8">
            {skills.map((skill) => {
                return (
                    <SkillCard key={`SkillCard_${++skillId}`} skillName={skill.skillName} skillIcon={skill.skillIcon}/>
                );
            })}
        </div>
    );
}

export default Skills;
